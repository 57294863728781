import axios from 'axios'
import * as Sentry from '@sentry/vue'
import Cookies from 'js-cookie'

import { errorStatus } from '@/constants/errorStatus'
import { isMedistream } from '@/utils/medistream'
import router from '@/router'

const getBaseUrl = () => {
  return process.env.NODE_ENV === 'development' ? '/overtax-individual' : process.env.VUE_APP_API_URL
}

const getCorpBaseUrl = () => {
  return process.env.NODE_ENV === 'development' ? '/overtax-corporation' : process.env.VUE_APP_CORP_API_URL
}

const getSfBaseUrl = () => {
  return process.env.NODE_ENV === 'development' ? '/overtax-sf' :`${process.env.VUE_APP_SF_API_URL}`
}
 
// 개인 경정청구 api
const instance = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
  allowAbsoluteUrls: false,
  headers: {
    'X-SERVICE-NAME': isMedistream() ? 'employment_incentive' : 'overtax',
  },
})
instance.interceptors.request.use((request) => {
  request.headers['X-CSRFToken'] = Cookies.get('csrftoken')
  return request
})
instance.interceptors.response.use((response) => {
  return response
}, (error) => {
  const status = error?.response?.status || ''
  if (status === errorStatus.PARAMETER_ERROR || status === errorStatus.FORBIDDEN ||  status === errorStatus.TIMEOUT || status === errorStatus.UNPROCESSABLE_CONTENT) {
    const errors = error.response.data.errors
    if (!errors || !Array.isArray(errors)) {
      Sentry.withScope((scope) => {
        scope.setTag('error-type', 'unexpected error format');
        Sentry.captureException(error);
      });
      return Promise.reject(error);
    } else {
      return Promise.reject(error.response.data.errors)
    }
  }

  // if (status === errorStatus.NOT_FOUND) {
  //   router.push({ name: '404' })
  // }

  if (status === errorStatus.SERVER_ERROR) {
    router.push({ name: '500' })
  }

  Sentry.withScope((scope) => {
    scope.setTag('error-type', 'unexpected error');
    Sentry.captureException(error);
  });
  return Promise.reject(error);
})

// 매직라인 api
const magicLineInstance = axios.create({
  baseURL: 'https://127.0.0.1:42235/',
  allowAbsoluteUrls: false,
})

// 인포텍 api
const infoTechInstance = axios.create({ 
  baseURL: 'https://127.0.0.1:16566/',
  allowAbsoluteUrls: false,
})

// 설문조사 api
const surveyInstance = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
  allowAbsoluteUrls: false,
})
surveyInstance.interceptors.request.use((request) => {
  request.headers['X-CSRFToken'] = Cookies.get('csrftoken')
  return request
})

// without token api
const noTokenInstance = axios.create({
  baseURL: getBaseUrl(),
  allowAbsoluteUrls: false,
})
noTokenInstance.interceptors.response.use((response) => {
  return response
}, (error) => {
  Sentry.captureException(error)
})

// 법인 api
const corpInstance = axios.create({ 
  baseURL: getCorpBaseUrl(),
  allowAbsoluteUrls: false,
})

// SF api
const sfInstance = axios.create({
  baseURL: getSfBaseUrl(),
  allowAbsoluteUrls: false,
})

export { 
  instance as axios,
  infoTechInstance as infoTechAxios,
  magicLineInstance as magicLineAxios,
  surveyInstance as surveyAxios,
  corpInstance as corpAxios,
  noTokenInstance as noTokenAxios,
  sfInstance as sfAxios,
}
