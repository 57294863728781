import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useUserStore } from '@/store/modules/user'
import { vueRouteToString } from '@/utils/urlParser'

import { getUser, GetUserResponse } from '@/api/user'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(
      /* webpackChunkName: "home" */
      '../views/HomeView'
    ),
  },
  {
    path: '/landing',
    name: 'landing',
    component: () => import(
      /* webpackChunkName: "landing" */
      '../views/LandingView'
    ),
  },
  {
    path: '/landing/select-entity',
    name: 'select-entity',
    component: () => import(
      /* webpackChunkName: "landing" */
      '../views/SelectEntityView'
    ),
  },
  {
    path: '/bookkeeping-consulting',
    name: 'consulting',
    component: () => import(
      /* webpackChunkName: "landing" */
      '../views/ConsultingView'
    ),
  },

  {
    path: '/user',
    name: 'user-parent',
    component: () => import(
      /* webpackChunkName: "user" */
      '../views/UserInfoView'
    ),
    children: [
      {
        path: '',
        name: 'user',
        component: () => import(
          /* webpackChunkName: "user" */
          '../views/UserInfoView/UserMenu.vue'
        ),
      },
      {
        path: 'profile',
        name: 'user-profile',
        component: () => import(
          /* webpackChunkName: "user" */
          '../views/UserInfoView/UserProfile.vue'
        ),
      },
      {
        path: 'contract',
        name: 'user-contract',
        component: () => import(
          /* webpackChunkName: "user" */
          '../views/UserInfoView/UserContract.vue'
        ),
      },
      {
        path: 'contract/:processId',
        name: 'user-contract-detail',
        component: () => import(
          /* webpackChunkName: "user" */
          '../views/UserInfoView/UserContractDetail.vue'
        ),
      },
      {
        path: 'payment/:processId',
        name: 'user-payment',
        component: () => import(
          /* webpackChunkName: "user" */
          '../views/UserInfoView/UserPayment.vue'
        ),
      },
      {
        path: 'payment/refund',
        name: 'user-payment-refund',
        component: () => import(
          /* webpackChunkName: "user" */
          '../views/UserInfoView/UserPaymentRefund.vue'
        ),
      },
      {
        path: 'account',
        name: 'user-account',
        component: () => import(
          /* webpackChunkName: "user" */
          '../views/UserInfoView/UserAccount.vue'
        ),
      },
      {
        path: 'withdrawal',
        name: 'user-withdrawal',
        component: () => import(
          /* webpackChunkName: "user" */
          '../views/UserInfoView/UserWithdrawal'
        ),
      },
    ],
  },
  {
    path: '/refund',
    name: 'refund',
    component: () => import(
      /* webpackChunkName: "refund" */
      '../views/RefundView'
    ),
  },
  {
    path: '/oauth',
    name: 'oauth',
    component: () => import(
      /* webpackChunkName: "login" */
      '../views/LoginView'
    ),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(
      /* webpackChunkName: "login" */
      '../views/LoginView'
    ),
  },
  {
    path: '/cert-center',
    name: 'cert-center',
    component: () => import(
      /* webpackChunkName: "cert" */
      '../views/CertCenterView/CertCenter.vue'
    ),
  },
  {
    path: '/cert',
    name: 'cert',
    component: () => import(
      /* webpackChunkName: "cert" */
      '../views/CertCenterView/CertView.vue'
    ),
  },
  {
    path: '/cert-corp',
    name: 'cert-corp',
    component: () => import(
      /* webpackChunkName: "cert-corp" */
      '../views/CertCenterView/CertCorpView.vue'
    ),
  },
  {
    path: '/easy-cert',
    name: 'easy-cert',
    component: () => import(
      /* webpackChunkName: "easy-cert" */
      '../views/EasyCertView'
    ),
  },
  {
    path: '/report',
    name: 'report',
    component: () => import(
      /* webpackChunkName: "report" */
      '../views/ReportView'
    ),
  },
  {
    path: '/survey',
    name: 'survey',
    component: () => import(
      /* webpackChunkName: "survey" */
      '../views/SurveyView/SurveyIntro.vue'
    ),
  },
  {
    path: '/survey_admin',
    name: 'survey_admin',
    component: () => import(
      /* webpackChunkName: "survey-admin" */
      '../views/SurveyAdmin'
    ),
  },
  {
    path: '/survey/operation',
    name: 'survey_operation',
    component: () => import(
      /* webpackChunkName: "survey-operation" */
      '../views/SurveyOperationView'
    ),
  },
  {
    path: '/survey/corporation',
    name: 'survey_corporation',
    component: () => import(
      /* webpackChunkName: "survey-corporation" */
      '../views/SurveyCorpView'
    ),
  },
  {
    path: '/survey/corporation/complete',
    name: 'survey_corporation_complete',
    component: () => import(
      /* webpackChunkName: "survey-corporation-complete" */
      '../views/SurveyCorpView/SurveyComplete.vue'
    ),
  },
  {
    path: '/survey/attach_id',
    name: 'attach_id',
    component: () => import(
      /* webpackChunkName: "attach_id" */
      '../views/SurveyView/AttachIDCard.vue'
    ),
  },
  {
    path: '/form',
    name: 'form',
    component: () => import(
      /* webpackChunkName: "form" */
      '../views/SurveyView'
    ),
    children: [
      {
        path: 'relative_start',
        name: 'relative_start',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/RelativeStart.vue'
        ),
      },
      {
        path: 'relative_add',
        name: 'relative_add',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/RelativeAdd.vue'
        ),
      },
      {
        path: 'relative_add_date',
        name: 'relative_add_date',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/RelativeAddDate.vue'
        ),
      },
      {
        path: 'relative_remove',
        name: 'relative_remove',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/RelativeRemove.vue'
        ),
      },
      {
        path: 'relative_remove_date',
        name: 'relative_remove_date',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/RelativeRemoveDate.vue'
        ),
      },
      {
        path: 'military_service',
        name: 'military_service',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/MilitaryService.vue'
        ),
      },
      {
        path: 'employment_info',
        name: 'employment_info',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/EmploymentInfo.vue'
        ),
      },
      {
        path: 'company_start/:companyId',
        name: 'company_start',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/CompanyStart.vue'
        ),
      },
      {
        path: 'company_basic/:companyId',
        name: 'company_basic',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/CompanyBasic.vue'
        ),
      },
      {
        path: 'business_before/:companyId',
        name: 'business_before',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/BusinessBefore.vue'
        ),
      },
      {
        path: 'business_type/:companyId',
        name: 'business_type',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/BusinessType.vue'
        ),
      },
      {
        path: 'business_keep/:companyId',
        name: 'business_keep',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/BusinessKeep.vue'
        ),
      },
      {
        path: 'business_type_keep/:companyId',
        name: 'business_type_keep',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/BusinessTypeKeep.vue'
        ),
      },
      {
        path: 'business_type_change/:companyId',
        name: 'business_type_change',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/BusinessTypeChange.vue'
        ),
      },
      {
        path: 'business_transfer/:companyId',
        name: 'business_transfer',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/BusinessTransfer.vue'
        ),
      },
      {
        path: 'business_transfer_address/:companyId',
        name: 'business_transfer_address',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/BusinessTransferAddress.vue'
        ),
      },
      {
        path: 'business_type_transfer/:companyId',
        name: 'business_type_transfer',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/BusinessTypeTransfer.vue'
        ),
      },
      {
        path: 'business_change/:companyId',
        name: 'business_change',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/BusinessChange.vue'
        ),
      },
      {
        path: 'business_intro/:companyId',
        name: 'business_intro',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/BusinessIntro.vue'
        ),
      },
      {
        path: 'business_transfer_employee/:companyId',
        name: 'business_transfer_employee',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/BusinessTransferEmployee.vue'
        ),
      },
      {
        path: 'regular_add/:companyId',
        name: 'regular_add',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/RegularAdd.vue'
        ),
      },
      {
        path: 'regular_add_date/:companyId',
        name: 'regular_add_date',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/RegularAddDate.vue'
        ),
      },
      {
        path: 'fulltime_add/:companyId',
        name: 'fulltime_add',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/FullTimeAdd.vue'
        ),
      },
      {
        path: 'fulltime_add_date/:companyId',
        name: 'fulltime_add_date',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/FullTimeAddDate.vue'
        ),
      },
      {
        path: 'has_contract/:companyId',
        name: 'has_contract',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/HasContract.vue'
        ),
      },
      {
        path: 'attach_contract/:companyId',
        name: 'attach_contract',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/AttachContract.vue'
        ),
      },
      {
        path: 'data_confirm',
        name: 'data_confirm',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/DataConfirm.vue'
        ),
      },
      {
        path: 'completed',
        name: 'completed',
        component: () => import(
          /* webpackChunkName: "form" */
          '../views/SurveyView/SurveyCompleted.vue'
        ),
      },
    ],
  },
  {
    path: '/subsidy',
    name: 'subsidy',
    component: () => import(
      /* webpackChunkName: "subsidy" */
      '../views/SubsidyView'
    ),
  },
  {
    path: '/error/404',
    name: '404',
    component: () => import(
      /* webpackChunkName: "404" */
      '../views/ErrorView/404.vue'
    ),
  },
  {
    path: '/error/500',
    name: '500',
    component: () => import(
      /* webpackChunkName: "500" */
      '../views/ErrorView/500.vue'
    ),
  },
  {
    path: '/icon',
    name: 'icon',
    component: () => import(
      /* webpackChunkName: "icon" */
      '../views/IconView'
    ),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import(
      /* webpackChunkName: "not-found" */
      '../views/ErrorView/404.vue'
    ),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const withoutOUI: string[] = [
    'login', 'oauth', 'cert', 'cert-corp', 'cert-center', 'survey', 'survey_admin', 'survey-detail', 'survey-complete', 'landing', 'select-entity', 'not-found', '404', '500', 'survey_operation', 'icon', 'survey_corporation','survey_corporation_complete', 'consulting',
  ]
  const nameString = to.name?.toString() || ''

  if (withoutOUI.includes(nameString) || to.query.survey_token) {
    next()
  } else {
    const userStore = useUserStore()
    const id = userStore.id || ''
    if (id) {
      getUser(id).then((res: GetUserResponse) => {
        userStore.setState(res)
        next()
      }).catch(() => {
        userStore.setInitState()
        next({ name: 'login', query: { refer: vueRouteToString(to.path, to.query) } })
      })
    } else {
      userStore.setInitState()
      next({ name: 'login', query: { refer: vueRouteToString(to.path, to.query) } })
    }
  }   
})

router.onError((error) => {
  if (error.name === 'ChunkLoadError') {
    window.location.href = '/'
  }
})

export default router
