export interface Error {
  code: errorCode | null;
  detail: string;
  attr: string | null;
}

export const enum errorStatus {
  PARAMETER_ERROR = 400,
  FORBIDDEN = 403,
  TIMEOUT = 408,
  NOT_FOUND = 404,
  UNPROCESSABLE_CONTENT = 422,  
  SERVER_ERROR = 500,
}

export const enum errorCode {
  kakao_account_without_phone = 'kakao_account_without_phone',
  permission_denied = 'permission_denied',
  no_overtax_target = 'no_overtax_target',
  company_founded_in_this_year = 'company_founded_in_this_year', // 최근 창업
  expired_hometax_easylogin = 'expired_hometax_easylogin',
  expired_easylogin_step = 'expired_easylogin_step',
  expired_kcomwel_easylogin = 'expired_kcomwel_easylogin',
  required_easylogin_confirm_on_kakao = 'required_easylogin_confirm_on_kakao',
  infotech_cloud_timeout = 'infotech_cloud_timeout',
  rrn_already_exists = 'rrn_already_exists'
}

export const getDefaultErrors = (detail: string): Error[] => {
  return [
    {
      code: null,
      detail: detail,
      attr: null,
    },
  ]
}

export const getErrorByAttr = (attr: string | null, errors: Error[]): Error => {
  if (!errors || !Array.isArray(errors)) {
    return getDefaultErrors('errors is not defined')[0]
  }
  return errors.find((error) => error.attr === attr) || getDefaultErrors('attr is not match')[0]
}
